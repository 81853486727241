import React from 'react'

function About() {

  return (
    <div>
      <h3>About NYC🎾TC</h3>
      <br></br>
          <p>New York City Tennis Club is a premier tennis match booking App.<br></br> 
          <br></br>
            Book all your tennis matches here at NYC area tennis clubs.<br></br>
          <br></br>
            It is a great way to stay active and make new friends.<br></br>
          <br></br>
            Join NYC TC today!<br></br> 
        </p>
    </div>
  )
}

export default About

    